<template>
    <div class="conta" :style="{height:i18n.locale.value==='zh'? '198rem' : '212rem'}">
        <div class="cont" :style="{height:i18n.locale.value==='zh'? '90rem' : '105rem'}">
            <div class="contNav">Contact Us</div>
            <div class="borderItem">
                <span></span> <span></span>
            </div>
            <h2>{{$t('meg.contact')}}</h2>
            <div class="text" :style="{height:i18n.locale.value==='zh'? '45rem' : '57rem'}">
                <p><img src="../../assets/img/icon_youxiangh5.png" alt="">info@vsteach.com</p>
                <p><img src="../../assets/img/icon_dianhuah5.png" alt="">021-611 55879</p>
                <p><img src="../../assets/img/icon_dizhih5.png" alt="">{{ $t('meg.citys') }}</p>
            </div>
        </div>
        <div class="map" id="map"></div>
    </div>
</template>

<script>
import { mapLoader } from '@/components/Map'
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
export default {
    name: 'MapAbout',
    setup() {
        const i18n = useI18n()
        var aMapObj = null // 地图存储
        const mapMounted = () => {
            mapLoader().then((AMap) => {
                aMapObj = new AMap.Map('map', {
                    resizeEnable: true, //是否监控地图容器尺寸变化
                    zoom: 18, //初始化地图层级
                    center: [121.357178, 31.227892],//中心点坐标
                    viewMode: '2D',//使用3D视图

                })
                aMapObj.add(
                    new AMap.Marker({
                        position: new AMap.LngLat(121.357100, 31.228169),
                        icon: require('@/assets/img/dizhi.png'),
                    })
                );
            })
        }

        onMounted(() => {
            mapMounted()
        })

        return {
           i18n
        }
    }

};
</script>

<style lang="scss" scoped>
.conta {
    width: 100%;
    height: 198rem;
    background: #FFFFFF;

    .cont {
        width: 100%;
        height: 90rem;
        background: linear-gradient(180deg, #0B82F1 10%, rgba(11,130,241,0) 100%);
        .contNav {
            font-size: 10rem;
            font-family: Arial-BoldMT, Arial;
            font-weight: normal;
            color: #f8f8f8;
            text-align: center;
            padding-top: 16rem;
            margin-bottom: 4rem;
            font-weight: 700;
        }

        .borderItem {
            width: 100%;
            display: flex;
            justify-content: center;

            span {
                height: 0.8rem;
                background-color: #fff;
            }

            span:nth-child(1) {
                width: 46px;
            }

            span:nth-child(2) {
                margin-left: 1rem;
                width: 0.8rem;
            }

        }

        h2 {
            text-align: center;
            font-size: 6.5rem;
            font-family: Arial;
            color: #fff;
            margin: 6rem 0;
            font-weight: 900;
        }

        .text {
            margin: 0 auto;
            width: 90%;
            height: 42rem;
            background-color: #F7F8F9;
            border-radius: 5rem ;
            img{
                width: 7rem;
                height: 7rem;
                margin-right: 4rem;
            }
            p {
                margin-left: 5rem;
                display: flex;
                align-items: center;
                font-size: 4.6rem;
            }

            p:nth-child(1) {
                padding-top: 8rem;
            }

            p:nth-child(2) {
               padding: 4rem  0;
            }

            p:nth-child(3) {
                padding-bottom: 8rem;
            }
        }
    }

    .map{
        margin: 0 auto;
        margin-top: 15rem;
        width: 90%;
        height: 80rem;
        border-radius: 5rem ;
    }
}
</style>