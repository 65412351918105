<template>
    <div class="about">
        <div class="abouts">
            <div class="aboutTitle">
                <div class="aboutMain">
                    <h3>Contact Us</h3>
                    <div class="border">
                        <span></span>
                        <span></span>
                    </div>
                    <h4>{{$t('meg.contact')}}</h4>
                    <p>{{$t('meg.email')}}：info@vsteach.com</p>
                    <p>{{$t('meg.service')}}</p>
                    <p>{{$t('meg.city')}}</p>
                </div>
            </div>
            <div class="map" id="map">

            </div>
        </div>
    </div>
</template>

<script>
import { mapLoader } from '@/components/Map'
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
export default {
    name: 'MapAbout',
    setup() {
        const i18n = useI18n()
        var aMapObj = null // 地图存储
        const mapMounted = () => {
            mapLoader().then((AMap) => {
                aMapObj = new AMap.Map('map', {
                    resizeEnable: true, //是否监控地图容器尺寸变化
                    zoom: 18, //初始化地图层级
                    center: [121.357178, 31.227892],//中心点坐标
                    viewMode: '2D',//使用3D视图

                })
                aMapObj.add(
                    new AMap.Marker({
                        position: new AMap.LngLat(121.357100, 31.228169),
                        icon: require('@/assets/img/dizhi.png'),
                    })
                );
            })
        }

        onMounted(() => {
            mapMounted()
        })

        return {
           i18n
        }
    }

};
</script>

<style lang="scss" scoped>
.about {
    width: 100%;
    height: 47.875rem;
    background-color: #fff;
    display: flex;
    align-items: center;

}

.abouts {
    display: flex;
    align-items: center;
   margin-left: 9.1875rem; 
}

.aboutTitle {
    width: 37.9375rem;
    height: 38.375rem;
    background: #1E65D6;
    flex-shrink: 0;

    .aboutMain {
      margin-left: 7.6875rem;

        h3 {
    
            padding-top: 6.375rem;
            font-weight: normal;
            font-family: Arial;
            color: rgba(255, 255, 255, 0.5);
            font-size: 3.375rem;
        }

        .border {
            margin: 2.3125rem 0 .8125rem 0;

            span {
                display: inline-block;
                background: #FFFFFF;
                opacity: 0.7;
            }

            span:nth-child(1) {
                width: 4.375rem;
                height: .25rem;
            }

            span:nth-child(2) {
                margin-left: .3125rem;
                width: 0.25rem;
                height: 0.25rem;
            }
        }
        h4 {
            font-size: 1.875rem;
            font-family: Light;
            color: #FFFFFF;
            margin-bottom: 4rem;
        }

        p {
            font-size: 1rem;
            font-family: Light;
            color: #FFFFFF;
            margin-bottom: 2rem;

        }
    }
}

.map {
    flex-shrink: 0;
    width: 62.875rem;
    height: 38.375rem;
}


@media screen and (max-width: 1366px) {
    .about {
    width: 100%;
    height: 502px;
    .abouts {
   .aboutTitle {
    width: 500px;
    height: 434px;
    .aboutMain {
    
       margin-left: 77px;
        h3 {
            padding-top: 69px;
            font-size: 54px;
        }

        .border {
        
           margin: 38px 0 0 0;
            span:nth-child(1) {
                width: 70px;
                height: 4px;
            }

            span:nth-child(2) {
                margin-left: 5px;
                width: 4px;
                height: 4px;
            }
        }
        h4 {
            margin-top: 10px;
            font-size: 30px;
            margin-bottom: 64px;
        }

        p {
            font-size: 16px;
            line-height: 32px;
            margin: 0;

        }
    }
}

}

.map {
    background-color: #000;
    width: 732px;
    height: 434px;
}
}

}
</style>