<template>
	<div :class="i18n.locale.value==='zh'? 'rationa':'rationas' ">
		<div class="products">
			<p class="load">Download <br />Software</p>
			<div class="border">
				<span></span>
				<span></span>
			</div>
			<h2>{{$t('meg.software')}}</h2>
		</div>
		<div class="rationaText">
			<div class="setpOnw">
				<span>Step1:</span>
				<p class="signText">{{$t('meg.softwareSingAccount')}}</p>
				<p class="signItem">{{$t('meg.softwareSingAccountDest')}}</p>
				<button class="sign" @click="sign">{{$t('meg.softwareSingBtn')}}</button>
			</div>
			<div class="productsRight">
				<span>Step2:</span>
				<p class="signText">{{$t('meg.softwareSingSet')}}</p>
				<p class="signItem">{{$t('meg.softwareSingSetDest')}}<br />{{$t('meg.softwareSingSetDestTow')}}</p>

				<button class="sign" @click="set">{{$t('meg.softwareSingTwo')}}</button>
			</div>
		</div>
		<div class="product">
			<p>Step3:</p>
			<p>{{$t('meg.softwareDownload')}}</p>
			<p>{{$t('meg.softwareDownloadDest')}}</p>
			<div class="down">
				<div class="text">
					<div class="text_img">
						<img src="../assets/img/gw.png" alt="">
					</div>
					<div class="title">
						<span>{{$t('meg.softwareExplain')}}</span>
						<span>{{$t('meg.softwareFile')}}</span>

						<button @click="operate">{{$t('meg.softwareSing')}}</button>
					</div>
				</div>
				<div class="text">
					<div class="text_img">
						<img src="../assets/img/hz.png" alt="">
					</div>
					<div class="title">
						<span>{{$t('meg.softwareVr')}}</span>
						<span>{{$t('meg.softwareFileTow')}}</span>
						<button @click="visit">{{$t('meg.softwareSing')}}</button>
					</div>
				</div>
			</div>
		</div>
		<el-dialog v-model="dialogVisible" :before-close="handleClose" :show-close="false">

			<div class="formBox" v-if="i18n.locale.value==='zh'">
				<img src="../assets/img/icon_guanbi@2x.png" alt="" class="img" @click="handImg">
				<el-form :model="form" label-width="auto" size="large">
					<el-form-item label="您的称呼：">

						<div class="code">
							<el-input v-model="form.name" placeholder="请告知我们如何称呼您" class="formInput" />
						</div>
					</el-form-item>
					<el-form-item label="注册公司：">
						<div class="code">
							<el-input v-model="form.company_name" placeholder="请填写您的公司全称" class="formInput" />
						</div>
					</el-form-item>

					<el-form-item label="注册电话：">
						<div class="phone">
							<span>+86 <img src="../assets/img/icon.png" alt=""></span>
							<el-input v-model="form.mobile" placeholder="请填写联系电话" class="phoneInp" />
						</div>
					</el-form-item>
					<el-form-item label="验证码：">
						<div class="code">
							<el-input v-model="form.code" placeholder="请输入验证码" class="codeInput" />
							<el-button class="codeBtn" @click="codeBtn" v-if="timerTf === false">{{ codeText }}
							</el-button>
							<el-button class="codeBtn" disabled v-else>{{ codeNum }}秒后重新获取</el-button>
						</div>
					</el-form-item>
					<el-form-item label="设置密码：">
						<div class="code">
							<el-input v-model="form.password" type="password" show-password placeholder="请设置登录密码"
								class="formInput" />
						</div>
					</el-form-item>
					<el-form-item label="联系电话：">
						<div class="code">
							<el-input v-model="form.telephone" placeholder="请输入联系电话" class="formInput" />
						</div>
					</el-form-item>
					<el-form-item label="需求描述：">
						<div class="code">
							<el-input v-model="form.demands" placeholder="可简要描述您期望实现的效果" class="formInput" />
						</div>
					</el-form-item>
					<el-form-item>
						<div class="btn" @click="onSubmit" @keydown.enter="onSubmit">
							提交
						</div>
					</el-form-item>
				</el-form>
			</div>
			<div class="formBoxTow" v-else>
				<img src="../assets/img/icon_guanbi@2x.png" alt="" class="img" @click="handImg">
				<el-form :model="form" label-width="auto" size="large">
					<el-form-item label="userName：">

						<div class="code">
							<el-input v-model="form.name" placeholder="Please let us know how to address you"
								class="formInput" />
						</div>
					</el-form-item>
					<el-form-item label="Registered company：">
						<div class="code">
							<el-input v-model="form.company_name"
								placeholder="Please fill in the full name of your company" class="formInput" />
						</div>
					</el-form-item>

					<el-form-item label="Registration Phone：">
						<div class="phone">
							<span>+86 <img src="../assets/img/icon.png" alt=""></span>
							<el-input v-model="form.mobile" placeholder="Please fill in the contact number"
								class="phoneInp" />
						</div>
					</el-form-item>
					<el-form-item label="Verification Code：">
						<div class="code">
							<el-input v-model="form.code" placeholder="Please enter the verification code"
								class="codeInput" />
							<el-button class="codeBtn" @click="codeBtn" v-if="timerTf === false">Get verification code
							</el-button>
							<el-button class="codeBtn" disabled v-else>{{ codeNum }}Reacquire in seconds</el-button>
						</div>
					</el-form-item>
					<el-form-item label="Set Password：">
						<div class="code">
							<el-input v-model="form.password" type="password" show-password
								placeholder="Please set the login password" class="formInput" />
						</div>
					</el-form-item>
					<el-form-item label="Contact phone：">
						<div class="code">
							<el-input v-model="form.telephone" placeholder="Please enter contact phone"
								class="formInput" />
						</div>
					</el-form-item>
					<el-form-item label="Requirement description：">
						<div class="code">
							<el-input v-model="form.demands"
								placeholder="Briefly describe the effect you expect to achieve" class="formInput" />
						</div>
					</el-form-item>
					<el-form-item>
						<div class="btn" @click="onSubmit" @keydown.enter="onSubmit">
							Submit
						</div>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
		<div class="mess" :style="{ display: mess ? 'block' : 'none' }">login was successful</div>
	</div>
</template>
<script>
	import {
		ref,
		reactive
	} from 'vue'
	import company from '@/api/company'
	import user from '@/api/user'
	import {
		ElMessage
	} from 'element-plus'
	import {
		useI18n
	} from 'vue-i18n'
	export default {
		name: 'MapRation',

		setup() {
			const i18n = useI18n()
			const mess = ref(false)
			//注册
			const dialogVisible = ref(false)
			const form = reactive({
				name: '',
				company_name: '',
				mobile: '',
				code: '',
				password: '',
				demands: '',
				telephone: ''
			})

			const sign = () => {
				dialogVisible.value = true
			}
			//设置账号 
			const set = () => {
				// window.location.href ='http://office.vsteach.com:8030'
				window.open('http://console.expo.vsteach.com', '_blank')
			}
			//提交
			// const msg = ref('')
			const onSubmit = () => {
				company.Register(form).then(() => {
					ElMessage.success('注册成功！')
					dialogVisible.value = false
				}).catch(err => {
					ElMessage.error(err)
				});
			}

			const codeText = ref('获取验证码')
			const codeNum = ref(60)
			var codeTime = null
			const timerTf = ref(false)
			//获取验证码
			const codeBtn = () => {
				if (form.mobile === "") {
					ElMessage.error('请输入注册电话')
					return
				} else {
					user.VerificCode(form.mobile,1,"").then(() => {
						codeTime = setInterval(() => {
							timerTf.value = true
							codeNum.value--
							if (codeNum.value < 0) {
								timerTf.value = false
								clearInterval(codeTime)
								codeNum.value = 60
							}
						}, 1000)
					}).catch((err) => {
						ElMessage.error(err)
					});
				}
			}

			const handImg = () => {
				dialogVisible.value = false
			}
			//讲解员操作端（PC）
			const operate = () => {
				window.location.href = "/VsExpoSetup.exe"
			}

			const visit = () => {
				window.location.href = "/VsExpoSetup.exe"
			}
			return {
				operate,
				visit,
				dialogVisible,
				sign,
				form,
				onSubmit,
				handImg,
				mess,
				codeBtn,
				codeNum,
				codeText,
				codeTime,
				timerTf,
				set,
				i18n
			}
		}
	};
</script>

<style lang="scss" scoped>
	:deep(.el-dialog__header) {
		padding: 0;
		margin: 0;
	}

	:deep(.el-dialog__body) {
		padding: 8px;
		margin: 0;
	}

	:deep(.el-dialog__close) {
		width: 30px;
		height: 30px;
		background: #000000;
		opacity: 0.5;
		border-radius: 50%;
		color: #FFFFFF;
	}

	.mess {
		width: 21.31rem;
		height: 4.5rem;
		background: rgba(0, 0, 0, 0.8);
		border-radius: 0.38rem;
		font-size: 1.75rem;
		line-height: 4.5rem;
		text-align: center;
		color: #FFFFFF;
		position: absolute;
		left: 48%;
		top: 42%;
		transform: translate(-50%, -50%);
	}

	.rationa,
	.rationas {
		width: 100%;
		height: 82.625rem;
		background: #F5F5F5;
		position: relative;

		.products {
			margin: 0 0 0 23rem;

			.load {
				padding-top: 9.375rem;
				font-size: 3.375rem;
				font-family: Arial;
				color: #D4D4D4;
			}

			.border {
				span {
					display: inline-block;
					background: #1E65D6;
				}

				span:nth-child(1) {
					width: 4.375rem;
					height: .25rem;
				}

				span:nth-child(2) {
					margin-left: .3125rem;
					width: 0.25rem;
					height: 0.25rem;
				}
			}

			h2 {
				margin-top: 1.375rem;
				font-size: 1.875rem;
				font-family: Light;
				color: #333333;

			}
		}

		.rationaText {
			display: flex;
			margin: 5.125rem 0 0 23rem;

			span {
				font-size: 1.875rem;
				font-family: Arial;
				color: #999999;
			}

			.signText {
				margin: 1rem 0 1rem 0;
				font-size: 1.125rem;
				font-family: Light;
				color: #333333;
			}

			.signItem {

				font-size: 14px;
				font-family: Light;
				color: #797979;
				line-height: 32px;
			}

			.sign {
				width: 160px;
				height: 50px;
				background: #1E65D6;
				border-radius: 4px;
				font-size: 16px;
				color: #F5F5F5;
				font-family: Light;
				outline: none;
				border: none;
				cursor: pointer;
			}

			.setpOnw {
				.sign {
					margin-top: 52px;
				}
			}

			.productsRight {
				margin-left: 31.5rem;

				.sign {
					margin-top: 20px;
				}
			}
		}

		.product {
			margin-left: 23rem;
			margin-top: 7.8125rem;

			p:nth-child(1) {
				font-size: 1.875rem;
				font-family: Arial;
				color: #999999;
			}

			p:nth-child(2) {
				font-size: 1.125rem;
				font-family: Light;
				color: #333333;
				margin: 1rem 0;
			}

			p:nth-child(3) {
				width: 71.5625rem;
				font-size: 14px;
				font-family: Light;
				color: #797979;
				line-height: 32px;

			}

			.down {
				margin-top: 3rem;
				display: flex;

				.text {
					flex-shrink: 0;
					display: flex;
					align-items: center;
					width: 34.25rem;
					height: 14rem;
					margin-right: 3.25rem;
					background: #FFFFFF;
					box-shadow: 0 0.28125rem 0.625rem 0.0625rem rgba(0, 0, 0, 0.07);


					.text_img {
						margin: 0 3.375rem 0 4.375rem;
						width: 6.625rem;
						height: 6.625rem;
						flex-shrink: 0;

						img {
							width: 100%;
							height: 100%;
						}
					}

					.title {
						span {
							display: block;
							font-family: Light;
						}

						span:nth-child(1) {
							font-size: 1.125rem;
							color: #333333;
						}

						span:nth-child(2) {
							font-size: 1rem;
							color: #A5A5A5;
							margin: 0.3rem 0;
						}

						button {
							width: 6.875rem;
							height: 2.25rem;
							background: #1E65D6;
							border-radius: .25rem;
							color: #FFFFFF;
							font-size: 14px;
							font-family: Light;
							border: none;
							outline: none;
							margin-top: 20px;
							cursor: pointer;
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 1900px) {
		.rationa {
			height: 1290px;
		}
	}

	@media screen and (max-width: 1920px) {
		.rationas {
			height: 1360px;

			.products {
				margin-left: 15rem;
			}

			.rationaText {
				margin-left: 15rem;

				.productsRight {
					margin-left: 15rem;
				}
			}

			.product {
				margin-left: 15rem;
			}
		}
	}

	@media screen and (max-width: 1550px) {

		.rationa {
			width: 100%;
			height: 1170px;

			.product {
				.down {
					.text {
						.title {
							span:nth-child(1) {
								font-size: 16px;
							}

							span:nth-child(2) {
								font-size: 14px;
							}

							button {
								width: 150px;
								height: 40px;
							}
						}
					}

				}
			}
		}
	}

	@media screen and (max-width: 1550px) {
		.rationas {
			height: 1200px;

			.rationaText {
				.setpOnw {
					min-width: 420px;
				}

				.productsRight {
					min-width: 633px;
				}
			}

			.product {
				.down {
					.text {
						width: 520px;
						height: 200px;

						.text_img {
							margin: 0 3.375rem 0 4.375rem;
							width: 80px;
							height: 80px;
							flex-shrink: 0;

							img {
								width: 100%;
								height: 100%;
							}
						}

						.title {
							span {
								display: block;
								font-family: Light;
							}

							span:nth-child(1) {
								font-size: 16px;
								color: #333333;
							}

							span:nth-child(2) {
								font-size: 14px;
								color: #A5A5A5;
								margin: 0.3rem 0;
							}

							button {
								width: 170px;
								height: 40px;
								background: #1E65D6;
								border-radius: .25rem;
								color: #FFFFFF;
								font-size: 14px;
								font-family: Light;
								border: none;
								outline: none;
								margin-top: 20px;
								cursor: pointer;
							}
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 1366px) {
		.rationa {
			width: 1366px;
			height: 1182px;
			background: #F5F5F5;

			.products {
				.load {
					font-size: 54px;
				}

				.border {
					span:nth-child(1) {
						width: 70px;
						height: 4px;
					}

					span:nth-child(2) {
						margin-left: 5px;
						width: 4px;
						height: 4px;
					}
				}

				h2 {
					font-size: 30px;
				}
			}

			.rationaText {
				span {
					font-size: 30px;

				}

				.signText {
					font-size: 18px;
				}

				.signItem {
					font-size: 14px;
					line-height: 32px;
				}

				.sign {
					width: 160px;
					height: 50px;
					background: #1E65D6;
					border-radius: 4px;
					font-size: 16px;
					color: #F5F5F5;
					font-family: Light;
					outline: none;
					border: none;
				}

				.setpOnw {
					.sign {
						margin-top: 52px;
					}
				}

				.productsRight {
					margin-left: 31.5rem;

					.sign {
						margin-top: 20px;
					}
				}
			}

			.product {
				p:nth-child(1) {
					font-size: 30px;
					font-family: Arial;
					color: #999999;
				}

				p:nth-child(2) {
					font-size: 18px;
					font-family: Light;
					color: #333333;
					margin: 1rem 0;
				}

				p:nth-child(3) {
					width: 82.5625rem;
					font-size: 14px;
					font-family: Light;
					color: #797979;
					line-height: 32px;

				}

				.down {
					margin-top: 40px;
					display: flex;

					.text {

						width: 420px;
						height: 170px;
						margin-right: 80px;

						.text_img {
							margin: 0 40px 0 60px;
							width: 100px;
							height: 100px;
						}

						.title {

							span:nth-child(1) {
								font-size: 18px;
							}

							span:nth-child(2) {
								font-size: 16px;
								color: #A5A5A5;
								margin: 10px 0;
							}

							button {
								width: 100px;
								height: 36px;
								border-radius: 4px;
								font-size: 14px;
							}
						}
					}
				}
			}

		}
	}

	.formBox {
		margin: 5rem 18rem;
		height: 28rem;
		position: relative;

		.img {
			width: 2.25rem;
			height: 2.25rem;
			position: absolute;
			right: -18rem;
			top: -5rem;
			z-index: 999;
			cursor: pointer;
		}

		.code {
			width: 31.67rem;
			height: 40px;
			display: flex;

			.codeInput {
				width: 16.88rem;
				height: 2.5rem;
				// height: 40px;

			}

			.codeBtn {
				width: 6.4rem;
				height: 2.5rem;
				// height: 40px;
				margin-left: 0.5rem;
			}
		}

		.btn {
			background: #1E65D6;
			margin: 16px 0 0 81px;
			width: 14.67rem;
			height: 3.33rem;
			line-height: 3.33rem;
			border-radius: .25rem;
			font-size: 1rem;
			font-weight: 400;
			color: #FFFFFF;
			text-align: center;
			cursor: pointer;

		}



		.formInput {
			width: 23.75rem;
			height: 2.5rem;

		}

		.phone {

			display: flex;
			width: 31.67rem;
			height: 2.5rem;

			.phoneInp {
				width: 20.5rem;
				height: 2.5rem;
			}

			span {
				width: 68px;
				height: 2.5rem;
				text-align: center;
				line-height: 2.4rem;
				border: 0.08rem solid #D9D9D9;
				border-radius: 4px 0 0 4px;
				border-right: 0;

			}

		}
	}

	.formBoxTow {
		margin: 5rem 10rem;
		height: 25rem;
		position: relative;

		.img {
			width: 2.25rem;
			height: 2.25rem;
			position: absolute;
			right: -10rem;
			top: -5rem;
			z-index: 999;
			cursor: pointer;
		}

		.code {
			width: 31.67rem;
			height: 40px;
			display: flex;

			.codeInput {
				width: 12.88rem;
				height: 2.5rem;
				// height: 40px;

			}

			.codeBtn {
				width: 10.4rem;
				height: 2.5rem;
				// height: 40px;
				margin-left: 0.5rem;
			}
		}

		.btn {
			background: #1E65D6;
			margin: 16px 0 0 81px;
			width: 14.67rem;
			height: 3.33rem;
			line-height: 3.33rem;
			border-radius: .25rem;
			font-size: 1rem;
			font-weight: 400;
			color: #FFFFFF;
			text-align: center;
			cursor: pointer;
			margin-left: 187px;

		}



		.formInput {
			width: 23.75rem;
			height: 2.5rem;

		}

		.phone {

			display: flex;
			width: 31.67rem;
			height: 2.5rem;

			.phoneInp {
				width: 20.5rem;
				height: 2.5rem;
			}

			span {
				width: 68px;
				height: 2.5rem;
				text-align: center;
				line-height: 2.4rem;
				border: 0.08rem solid #D9D9D9;
				border-radius: 4px 0 0 4px;
				border-right: 0;

			}

		}
	}

	@media screen and (max-width: 1920px) {
		.formBox {
			margin-left: 200px;
			height: 460px;
			position: relative;

			.img {
				width: 36px;
				height: 36px;
				position: absolute;
				right: -289px;
				top: -82px;
				z-index: 999;
			}

			.code {
				color: #000;
				width: 500px;
				display: flex;

				.codeInput {
					width: 260px;
					height: 40px;
					flex-shrink: 0;
				}

				.codeBtn {
					width: 160px;
					height: 40px;
					margin-left: 0.5rem;
				}
			}

			.btn {
				flex-shrink: 0;
				margin: 16px 0 0 82px;
				text-align: center;
				width: 200px;
				height: 50px;
				line-height: 50px;
				background: #1E65D6;
				border-radius: 4px;
				font-size: 16px;
				font-weight: 400;
				color: #FFFFFF;
				cursor: pointer;
			}



			.formInput {
				width: 430px;
				height: 40px;

			}

			.phone {

				display: flex;
				width: 500px;
				height: 40px;

				.phoneInp {
					width: 369px;
					height: 40px;
				}

				span {
					width: 60px;
					height: 40px;
					text-align: center;
					line-height: 38px;
					// border: 1px solid #D9D9D9;

				}

			}
		}
	}

	@media screen and (max-width: 1550px) {
		.formBox {
			margin-left: 150px;
			height: 460px;
			position: relative;

			.img {
				width: 36px;
				height: 36px;
				position: absolute;
				right: -229px;
				top: -60px;
				z-index: 999;
			}

			.code {
				color: #000;
				width: 500px;
				display: flex;

				.codeInput {
					width: 186px;
					height: 40px;
					flex-shrink: 0;
				}

				.codeBtn {
					width: 120px;
					height: 40px;
					margin-left: 0.5rem;
				}
			}

			.btn {
				flex-shrink: 0;
				margin: 16px 0 0 82px;
				text-align: center;
				width: 200px;
				height: 50px;
				line-height: 50px;
				background: #1E65D6;
				border-radius: 4px;
				font-size: 16px;
				font-weight: 400;
				color: #FFFFFF;
				cursor: pointer;
			}



			.formInput {
				width: 312px;
				height: 40px;

			}

			.phone {

				display: flex;
				width: 500px;
				height: 40px;

				.phoneInp {
					width: 252px;
					height: 40px;
				}

				span {
					width: 60px;
					height: 40px;
					text-align: center;
					line-height: 38px;
					// border: 1px solid #D9D9D9;

				}

			}
		}
	}

	@media screen and (max-width: 1920px) {
		.formBoxTow {
			// margin: 5rem 10rem;
			height: 25rem;
			position: relative;

			.img {
				width: 2.25rem;
				height: 2.25rem;
				position: absolute;
				right: -10rem;
				top: -5rem;
				z-index: 999;
				cursor: pointer;
			}

			.code {
				width: 31.67rem;
				height: 40px;
				display: flex;

				.codeInput {
					width: 10.88rem;
					height: 2.5rem;
					// height: 40px;

				}

				.codeBtn {
					width: 12.4rem;
					height: 2.5rem;
					// height: 40px;
					margin-left: 0.5rem;
				}
			}

			.btn {
				background: #1E65D6;
				margin: 16px 0 0 81px;
				width: 14.67rem;
				height: 3.33rem;
				line-height: 3.33rem;
				border-radius: .25rem;
				font-size: 1rem;
				font-weight: 400;
				color: #FFFFFF;
				text-align: center;
				cursor: pointer;
				margin-left: 187px;

			}



			.formInput {
				width: 23.75rem;
				height: 2.5rem;

			}

			.phone {

				display: flex;
				width: 31.67rem;
				height: 2.5rem;

				.phoneInp {
					width: 19.5rem;
					height: 2.5rem;
				}

				span {
					width: 68px;
					height: 2.5rem;
					text-align: center;
					line-height: 2.4rem;
					border: 0.08rem solid #D9D9D9;
					border-radius: 4px 0 0 4px;
					border-right: 0;

				}

			}
		}
	}

	@media screen and (max-width: 1550px) {
		.formBoxTow {
			margin: 58px;
			margin-bottom: 30px;
			height: 480px;
			position: relative;

			.img {
				width: 2.25rem;
				height: 2.25rem;
				position: absolute;
				right: -5rem;
				top: -5rem;
				z-index: 999;
				cursor: pointer;
			}

			.code {
				height: 40px;
				display: flex;

				.codeInput {
					width: 180px;
					height: 40px;
				}

				.codeBtn {
					width: 190px;
					height: 40px;
					// height: 40px;
					margin-left: 0.5rem;
				}
			}

			.btn {
				background: #1E65D6;
				margin: 16px 0 0 81px;
				width: 150px;
				height: 40px;
				line-height: 40px;
				border-radius: 4px;
				font-size: 16px;
				font-weight: 400;
				color: #FFFFFF;
				text-align: center;
				cursor: pointer;
				margin-left: 187px;

			}



			.formInput {
				width: 380px;
				height: 40px;

			}

			.phone {

				display: flex;
				// width: 31.67rem;
				height: 40px;

				.phoneInp {
					width: 310px;
					height: 40px;
				}

				span {
					width: 68px;
					height: 40px;
					text-align: center;
					line-height: 40px;
					border: 0.08rem solid #D9D9D9;
					border-radius: 4px 0 0 4px;
					border-right: 0;

				}

			}
		}
	}
</style>
