import Grpc from '@/utils/http/request';

import {
	RegisterCompanyReq
} from "@/pbs/company_pb";
import {
	CompanyServiceClient
} from "@/pbs/company_grpc_web_pb";

class Company extends Grpc {
	constructor() {
		super(CompanyServiceClient);
	}
	
	
	/**
	 * 注册企业
	 * @param {String} name
	 * @param {String} companyName 
	 * @param {String} passwd
	 * @param {String} mobile
	 * @param {String} code
	 * @param {String} requirement
	 * @param {String} phone
	 */
	Register(data) {
		return new Promise((resolve, reject) => {
			let request = new RegisterCompanyReq();
			request.setName(data.name);//用户名
			request.setCompanyName(data.company_name);//企业名称
			request.setPassword(data.password);//密码
			request.setMobile(data.mobile);//手机号码
			request.setCode(data.code);//手机验证码
			request.setDemands (data.demands);//期望说明
			request.setTelPhone(data.telephone);//企业联系电话
			this.service.register(request, this.metadata, (err, response) => {
				if (err) {
					reject(err.message);
				} else {
					resolve(response);
				}
			});
		});
	}
}
// 获取验证码
export default new Company();
