<template>
    <div class="news">
    
        <div class="products">
            <p class="newTitle">News<br />
                Information</p>
            <div class="border">
                <span></span>
                <span></span>
            </div>
            <h2>{{ $t('meg.news') }}</h2>
            <div class="text">
                <h5>{{ $t('meg.regard') }}</h5>
                <p>{{ $t('meg.date') }}</p>
            </div>
        </div>
        <div :id="i18n.locale.value==='zh'? 'a':'b' ">
            <p>{{ $t('meg.newTitle') }}</p>
            <button class="btn" @click="toLink">{{ $t('meg.newDetail') }}</button>
        </div>
    </div>


</template>

<script>
import { useI18n } from 'vue-i18n'
export default {
    name: 'WebNews',
    setup() {
        const i18n = useI18n()
        const toLink = () => {
            window.location.href = "https://www.vsteach.com/#/about"
        }
        return {
            toLink,
            i18n
        }
    }

};
</script>

<style lang="scss" scoped>
.news {
    width: 100%;
    height: 59.375rem;
    background: url(../assets/img/bg2.png) no-repeat;
    background-size: 100% 100%;
    display: flex;

    .products {
        flex-shrink: 0;
        margin-left: 23rem;
        margin-top: 12.75rem;

        .newTitle {
            color: #E8E8E8;
            font-family: Arial;
            font-size: 3.375rem;

        }

        .border {
            // margin-top: -1rem;

            span {
                display: inline-block;
                background: #FFFFFF;
            }

            span:nth-child(1) {
                width: 4.375rem;
                height: .25rem;
            }

            span:nth-child(2) {
                margin-left: .3125rem;
                width: 0.25rem;
                height: 0.25rem;
            }
        }

        h2 {
            margin-top: 1.625rem;
            font-size: 1.875rem;
            font-family: Light;
            color: #FFFFFF;
        }

        .text {
            margin-top: 5rem;

            font-family: Light;
            color: #FFFFFF;

        }

        h5 {
            font-size: 1.25rem;
            font-family: Light;
            font-weight: normal;
        }

        p {
            margin-top: 1.5rem;
            font-size: 14px;
            color: #FFFFFF;
            font-family: Light;

        }
    }

    #a {
        width: 100px;
        height: 100px;
        flex-shrink: 0;
        height: 6.5rem;
        margin-top: 43.5rem;
        margin-left: 30rem;

        p {
            font-size: 1rem;
            color: #FFFFFF;
            font-family: Light;
            margin-bottom: 1rem;
            width: 31rem;
            line-height: 36px;
        }

        .btn {
            margin-top: 1.5rem;
            border: 0;
            outline: none;
            font-size: 1rem;
            font-family: Light;
            color: #3D5C9D;
            width: 10rem;
            height: 3.125rem;
            background: #FFFFFF;
            border-radius: 0.25rem;
            cursor: pointer;
        }
    }
    #b{
        flex-shrink: 0;
        height: 6.5rem;
        margin-top: 43.5rem;
        margin-left: 30rem;

        p {
            font-size: 1rem;
            color: #FFFFFF;
            font-family: Light;
            margin-bottom: 1rem;
            width: 31rem;
        }

        .btn {
            margin-top: 1.5rem;
            border: 0;
            outline: none;
            font-size: 1rem;
            font-family: Light;
            color: #3D5C9D;
            width: 10rem;
            height: 3.125rem;
            background: #FFFFFF;
            border-radius: 0.25rem;
            cursor: pointer;
        }
    
    }
}

@media screen and (max-width: 1550px) {
    .news {
        width: 100%;
        height: 790px;

        .products {
            margin-top: 114px;
            margin-left: 320px;

            .newTitle {
                font-size: 54px;

            }

            .border {
                span:nth-child(1) {
                    width: 70px;
                    height: 4px;
                }

                span:nth-child(2) {
                    width: 4px;
                    height: 4px;
                    margin-left: 5px;
                }
            }

            h2 {
                margin-top: 26px;
                font-size: 30px;
            }

            .text {
                margin-top: 80px;
            }

            h5 {
                font-size: 20px;
            }

            p {
                font-size: 14px;
                margin-top: 24px;
            }
        }

        #a {
            margin-top: 587px;
            margin-left: 22rem;
            height: 104px;

            p {
                font-size: 16px;
                line-height: 26px;
                width: 560px;
            }

            .btn {

                font-size: 16px;
                width: 110px;
                height: 40px;
                border-radius: 4px;
            }
        }

        #b {
            margin-top: 552px;
            margin-left: 19rem;
            height: 104px;

            p {
                font-size: 16px;
                line-height: 26px;
                width: 560px;
            }

            .btn {

                font-size: 16px;
                width: 170px;
                height: 40px;
                border-radius: 4px;
            }
        }
    }

}

@media screen and (max-width: 1366px) {
    .news {
        width: 1366px;
        height: 686px;

        .products {
            margin-top: 118px;
            margin-left: 237px;

            .newTitle {
                font-size: 54px;

            }

            .border {
                span:nth-child(1) {
                    width: 70px;
                    height: 4px;
                }

                span:nth-child(2) {
                    width: 4px;
                    height: 4px;
                    margin-left: 5px;
                }
            }

            h2 {
                margin-top: 26px;
                font-size: 30px;
            }

            .text {
                margin-top: 26px;

            }

            h5 {
                font-size: 20px;

            }

            p {
                margin-top: 16px;
                font-size: 14px;
            }
        }

        #a {
            margin-top: 492px;
            margin-left: 282px;

            p {
                font-size: 16px;
                line-height: 32px;
            }

            .btn {
                margin-top: 10px;

                font-size: 16px;

                width: 110px;
                height: 40px;

                border-radius: 4px;
            }
        }

        #b {
            margin-top: 454px;
            margin-left: 245px;

            p {
                font-size: 16px;
                line-height: 28px;
            }

            .btn {
                margin-top: 10px;
                font-size: 16px;
                width: 170px;
                height: 40px;
                border-radius: 4px;
            }
        }
    }

}
@media screen and (min-width: 1920px) {
    .news {
    width: 100%;
    height: 59.375rem;
    background: url(../assets/img/bg2.png) no-repeat;
    background-size: 100% 100%;
    display: flex;
    #a {
      
        margin-left: 50rem;

        p {
            font-size: 1rem;
            color: #FFFFFF;
            font-family: Light;
            margin-bottom: 1rem;
            width: 31rem;
            line-height: 36px;
        }

        .btn {
            margin-top: 1.5rem;
            border: 0;
            outline: none;
            font-size: 1rem;
            font-family: Light;
            color: #3D5C9D;
            width: 10rem;
            height: 3.125rem;
            background: #FFFFFF;
            border-radius: 0.25rem;
            cursor: pointer;
        }
    }
    #b{
        flex-shrink: 0;
        height: 6.5rem;
        margin-top: 43.5rem;
        margin-left: 50rem;

        p {
            font-size: 1rem;
            color: #FFFFFF;
            font-family: Light;
            margin-bottom: 1rem;
            width: 31rem;
        }

        .btn {
            margin-top: 1.5rem;
            border: 0;
            outline: none;
            font-size: 1rem;
            font-family: Light;
            color: #3D5C9D;
            width: 10rem;
            height: 3.125rem;
            background: #FFFFFF;
            border-radius: 0.25rem;
            cursor: pointer;
        }
    
    }
}
}
</style>