<template>
    <div>
        <div class="banner" v-if="i18n.locale.value==='zh'">
        <el-carousel arrow="always" >
            <el-carousel-item v-for="(item, i) in banner" :key="i" :label="item.id" >
                <img :src="item.url" alt="" class="swiper">
            </el-carousel-item>
        </el-carousel>
    </div>
    <div class="banner" v-else>
        <el-carousel arrow="always">
            <el-carousel-item v-for="(item, i) in banners" :key="i" :label="item.id" >
                <img :src="item.url" alt="" class="swiper">
            </el-carousel-item>
        </el-carousel>
    </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
export default {
    setup() {
        const i18n = useI18n()
        const banner = ref([
            { url: require("@/assets/img/banner1x.png"), id: '01' },
            { url: require("@/assets/img/banner2.png"), id: '02' },
            { url: require("@/assets/img/banner3.png"), id: '03' },
        ])
        const banners = ref([
            { url: require("@/assets/img/banner4.png"), id: '01' },
            { url: require("@/assets/img/banner5.png"), id: '02' },
            { url: require("@/assets/img/banner6.png"), id: '03' },
        ])
        return {
            banner,
            banners,
            i18n
        }
    }
}
</script>
<style scoped lang="scss">
.banner {
    width: 100%;
    height: 57.125rem;

    :deep(.el-carousel__container) {
        height: 57.13rem !important;
    }

    .swiper {
        width: 100%;
        height: 100%;
    }
}

:deep(.el-carousel__indicators--labels .el-carousel__button) {
    color: #fff;
    font-size: 1.5rem;
    font-family: Arial;
}

:deep(.el-carousel__button span) {
    margin-left: -12rem;
    position: absolute;
    top: -2rem;
}

:deep(.el-carousel__arrow) {
    font-size: 2.6rem;
    font-weight: bold;
}

:deep(.el-carousel__arrow--left),
:deep(.el-carousel__arrow--right) {
    width: 2.8rem;
    height: 5.8rem;
    background-color: rgba(0, 0, 0, .5);
    border-radius: 0;
    text-align: center;
}

:deep(.el-carousel__arrow--left) {
    left: 5.2rem;
}

:deep(.el-carousel__arrow--right) {
    right: 5.2rem;
}

:deep(.el-carousel__indicator--horizontal .el-carousel__button) {

    width: 23rem;
    flex-shrink: 0;
    height: 0.25rem;
    margin-bottom: 6rem;
    flex-shrink: 0;
}

:deep(.is-active .el-carousel__button) {
    background: #1E65D6;
    color: #1E65D6;
}

@media screen and (max-width: 1366px) {

    .banner {
        width: 1366px;
        height: 650px;

        :deep(.el-carousel__container) {
            height: 650px !important;
        }

        .swiper {
            width: 100%;
            height: 100%;
        }

        :deep(.el-carousel__arrow--left),
        :deep(.el-carousel__arrow--right) {
            width: 30px;
            height: 60px;
            background-color: rgba(0, 0, 0, .5);
            border-radius: 0;
            text-align: center;
        }

        :deep(.el-carousel__arrow) {
            font-size: 30px;
            font-weight: 900;
        }

    
    }
}
</style>