// function setRem() {
//   // 默认使用16px作为基准大小
//   const baseSize = 16;
//   const baseVal = baseSize / 1920;
//   const vW = window.innerWidth; // 当前窗口的宽度
//   const rem = vW * baseVal; // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应font-size值
 
//   window.$size = rem / 16;
//   document.documentElement.style.fontSize = rem + "px";
// }
// // 初始化
// setRem();
// // // 窗口大小变化时重置 rem
// window.onresize = function () {
//   setRem();
// };


(function(doc, win, baseW) {
  var docEl = doc.documentElement,
    resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize';
  //   isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent);
  // 设置data-dpr属性，留作的css hack之用
  var dpr = ( window.devicePixelRatio * 1 ).toFixed(2) || 1;
  dpr = dpr == Math.ceil(dpr) ? Math.ceil(dpr) : dpr;
  docEl.setAttribute('data-dpr', dpr);
 
  var recalc = function(){
    var clientWidth = docEl.clientWidth;
    if(!clientWidth) return;
    clientWidth = clientWidth >= baseW ? baseW : clientWidth;
    docEl.style.fontSize = 16 * ( clientWidth / baseW) + 'px'; 
    // console.log(docEl.style.fontSize);
  };
  if (!doc.addEventListener) return;
  recalc()
  win.addEventListener(resizeEvt, recalc, false);
  doc.addEventListener('DOMContentLoaded', recalc, false);
})(document, window, 1920);