<template>
    <div class="swiperItem"  v-if="i18n.locale.value === 'zh'">
        <el-carousel arrow="always" >
            <el-carousel-item v-for="(item, i) in bannerItem" :key="i" :label="item.id" >
                <img :src="item.url" alt="" class="swiper">
            </el-carousel-item>
        </el-carousel>
    </div>
    <div class="swiperItem" v-else>
        <el-carousel arrow="always" >
            <el-carousel-item v-for="(item, i) in bannerItems" :key="i" :label="item.id" >
                <img :src="item.url" alt="" class="swiper">
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
 import {ref} from 'vue'
 import { useI18n } from 'vue-i18n'
export default {
    setup(){
        const i18n = useI18n()
        const bannerItem = ref([
            { url: require("@/assets/img/banner1h5.png"), id: '01' },
            { url: require("@/assets/img/banner2h5.png"), id: '02' },
            { url: require("@/assets/img/banner3h5.png"), id: '03' },
        ])
        const bannerItems = ref([
            { url: require("../../assets/img/banner1e.png"), id: '01' },
            { url: require("../../assets/img/banner2e.png"), id: '02' },
            { url: require("../../assets/img/banner3e.png"), id: '03' },
        ])
        return {
            bannerItem,
            bannerItems,
            i18n
        }
    }
};
</script>

<style lang="scss" scoped>
  .swiperItem{
    width: 100%;
    height: 100rem;
    :deep(.el-carousel__container) {
        height: 100rem !important;
    }

    .swiper {
        width: 100%;
        height: 100%;
    }


:deep(.el-carousel__indicators--labels .el-carousel__button) {
    color: #fff;
    font-size: 2rem;
    font-family: Arial;
}

:deep(.el-carousel__button span) {
    margin-left: -12rem;
    position: absolute;
    top: -2rem;
}

:deep(.el-carousel__arrow) {
    font-size: 5rem;
    font-weight: bold;
}

:deep(.el-carousel__arrow--left),
:deep(.el-carousel__arrow--right) {
    // width: 6rem;
    // height: 10rem;
    // background-color: rgba(0, 0, 0, .5);
    // border-radius: 0;
    // text-align: center;
    display: none;
}

:deep(.el-carousel__arrow--left) {
    left: 5.2rem;
}

:deep(.el-carousel__arrow--right) {
    right: 5.2rem;
}

:deep(.el-carousel__indicator--horizontal .el-carousel__button) {

    width: 23rem;
    flex-shrink: 0;
    height: 3px;
    margin-bottom: 6rem;
    flex-shrink: 0;
}

:deep(.is-active .el-carousel__button) {
    background: #1E65D6;
    color: #1E65D6;
}
  }
</style>