<template>
    <div class="rat" :style="{height:i18n.locale.value==='zh'? '398rem' : '490rem'}">
        <div class="ratNav">Download Software</div>
        <div class="borderItem">
            <span></span> <span></span>
        </div>
        <h2>{{$t('meg.software')}}</h2>
        <div class="step-noe" :style="{height:i18n.locale.value==='zh'? '68rem' : '80rem'}">
            <div class="noe">
                <p>Step1:</p>
                <div class="bg"></div>
                <p>{{$t('meg.softwareSingAccount')}}</p>
                <p>{{$t('meg.softwareSingAccountDest')}}</p>
                <button @click="toSing">{{$t('meg.softwareSingBtn')}}</button>
            </div>
           <div class="img">
            <img src="../../assets/img/jiantouh5.png" alt="" class="oneImg">
           </div>
        </div>
       
        <div class="step-two" :style="{height:i18n.locale.value==='zh'? '90rem' : '118rem'}">
            <div class="two">
                <p>Step2:</p>
                <div class="bg"></div>
                <p>{{$t('meg.softwareSingSet')}}</p>
                <p>{{$t('meg.softwareSingSetDest')}}</p>
                <p>{{$t('meg.softwareSingSetDestTow')}}</p>
                <button  @click="set">{{$t('meg.softwareSingTwo')}}</button>
            </div>
            <div class="img">
            <img src="../../assets/img/jiantouh5.png" alt="" class="oneImg">
           </div>
        </div>
        <div class="step-three" :style="{height:i18n.locale.value==='zh'? '170rem' : '225rem'}">
            <div class="three">
                <p>Step3:</p>
                <div class="bg"></div>
                <p>{{$t('meg.softwareDownload')}}</p>
                <p>{{$t('meg.softwareDownloadDest')}}</p>
                <div class="down">
                    <div class="imgText">
                        <img src="../../assets/img/bg(1)h5.png" alt="">
                        <div class="text">
                            <p>{{$t('meg.softwareExplain')}}</p>
                            <p> {{$t('meg.softwareFile')}}</p>
                        </div>
                    </div>
                    <span @click="operate">{{$t('meg.softwareSing')}}</span>
                </div>
                <div class="down">
                    <div class="imgText">
                        <img src="../../assets/img/bg(2)h5.png" alt="">
                        <div class="text">
                            <p>{{$t('meg.softwareVr')}}</p>
                            <p>{{$t('meg.softwareFileTow')}}</p>
                        </div>
                    </div>
                    <span @click="operate">{{$t('meg.softwareSing')}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useI18n } from 'vue-i18n'

export default {
  setup() {
    const i18n = useI18n()
    //设置账号 
    const set = () => {
      // window.location.href ='http://office.vsteach.com:8030'
      window.open('http://office.vsteach.com:8030', '_blank')
    }
    //
    const operate = () => {
      window.location.href = "/VsExpoSetup.exe"
    }
    //注册
 
  const toSing = () => {
    window.location.href = '/sign'
  }
    return {
      set,
      operate,
      i18n,
      toSing
      
    }
  }
};
</script>

<style lang="scss" scoped>
.rat {
    width: 100%;
    // height: 460rem;
    background: #F2F2F5;

    .ratNav {
        font-size: 10rem;
        font-family: Arial-BoldMT, Arial;
        color: #CCCCCC;
        text-align: center;
        padding-top: 16rem;
        margin-bottom: 4rem;
        font-weight: 700;
    }

    .borderItem {
        width: 100%;
        display: flex;
        justify-content: center;

        span {
            height: 0.8rem;
            background-color: #1E65D6;
        }

        span:nth-child(1) {
            width: 46px;
        }

        span:nth-child(2) {
            margin-left: 1rem;
            width: 0.8rem;
        }

    }

    h2 {
        text-align: center;
        font-size: 6.5rem;
        font-family: Arial;
        color: #333333;
        margin: 6rem 0;
    }

    .step-noe {
        margin: 0 auto;
        width: 90%;
        // height: 68rem;
        border-radius: 2rem;
        border-top: 0.5rem solid #fff;
        border-bottom: 0.5rem solid #fff;
        background-color: #F7F8F9;
        .img{
            width: 100%;
            // margin: 0 auto;
            display: flex;
            justify-content: center;
            .oneImg{
                margin-top: 4rem;
            width: 12rem;
            height: 12rem;
            
        }
        }
        .noe {
            margin-left: 5.3rem;

            p:nth-child(1) {
                font-size: 8rem;
                color: #333333;
                margin: 6rem 0 0 0;
            }

            p:nth-child(3) {
                font-size: 4.6rem;
                color: #333333;
            }

            p:nth-child(4) {
                font-size: 4.6rem;
                color: #797979;
                margin: 5rem 0;
            }


        }
    }

    .bg {
        width: 23.5rem;
        height: 2.6rem;
        background: linear-gradient(270deg, rgba(30, 101, 214, 0) 0%, #1E65D6 100%);
        opacity: 0.5;
        margin-top: -2rem;
        margin-bottom: 5.3rem;
    }

    .step-two {
        margin: 4rem auto;
        width: 90%;
        // height: 84rem;
        border-radius: 2rem;
        border-top: 0.5rem solid #fff;
        border-bottom: 0.5rem solid #fff;
        background-color: #F7F8F9;
        .img{
            width: 100%;
            // margin: 0 auto;
            display: flex;
            justify-content: center;
            .oneImg{
                margin-top: 4rem;
            width: 12rem;
            height: 12rem;
            
        }
        }
        .two {
            margin-left: 5.3rem;

            p:nth-child(1) {
                font-size: 8rem;
                color: #333333;
                margin: 6rem 0 0 0;
            }

            p:nth-child(3) {
                font-size: 4.7rem;
                color: #333333;

            }

            p:nth-child(4) {
                font-size: 4.7rem;
                color: #797979;
                margin-top: 3rem;
                line-height: 10rem;
            }

            p:nth-child(5) {
                font-size: 4.7rem;
                color: #797979;
                margin: 4rem 0 5rem 0;
            }

        }
    }

    button {
        width: 50rem;
        height: 14.5rem;
        border: 0;
        outline: none;
        background-color: #1E65D6;
        border-radius: 1.3rem;
        color: #fff;
        font-size: 6rem;
        color: #fff;
    }

    .step-three {
        margin: 0 auto;
        width: 90%;
        height: 138rem;
        border-radius: 2rem;
        border-top: 0.5rem solid #fff;
        border-bottom: 0.5rem solid #fff;
        background-color: #F7F8F9;

        .three {
            margin: 0 5.3rem;

            p:nth-child(1) {
                font-size: 8rem;
                color: #333333;
                margin: 6rem 0 0 0;
            }

            p:nth-child(3) {
                font-size: 4.7rem;
                color: #333333;

            }

            p:nth-child(4) {
                font-size: 4.7rem;
                color: #797979;
                line-height: 9rem;
                margin: 3rem 0 8rem 0;
            }

            .down {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 16rem 0 0 0;

                .imgText {
                    display: flex;
                }

                img {
                    width: 20rem;
                    height: 20rem;
                }

                .text {
                    margin-left: 4rem;

                    p:nth-child(1) {

                        font-size: 5rem;
                        color: #333333;
                        margin: 2.5rem 0 2rem 0;

                    }

                    p:nth-child(2) {

                        font-size: 4rem;
                        color: #A5A5A5;

                    }
                }

                span {
                    display: block;
                    width: 23rem;
                    height: 9rem;
                    background: #1E65D6;
                    border-radius: 5rem;
                    color: #fff;
                    font-size: 4rem;
                    text-align: center;
                    line-height: 9rem;
                    overflow: hidden;
                }
            }
            // .down:nth-child(1){
            //     margin: 5rem 0 11rem 0;
            // }
        }
    }
}
</style>