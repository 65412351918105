<template>
    <div class="mear" :style="{height:i18n.locale.value==='zh'? '214rem' : '240rem'}">
        <div class="mearNav">Merchant Products</div>
        <div class="borderItem">
            <span></span> <span></span>
        </div>
        <h2>{{ $t('meg.merchants') }}</h2>
        <h6>{{ $t('meg.machine') }}</h6>
        <p>{{ $t('meg.porduceDest') }} </p>
        <div class="uit">
            <img src="../../assets/img/3D.jpg" alt="">
            <p @click="$router.push('/unity')">{{ $t('meg.interaction') }}<img src="../../assets/img/zoominh5.png" alt=""></p>
        </div>
        
    </div>
</template>

<script>
    import { useI18n } from 'vue-i18n'
export default {

    setup() {
        const i18n = useI18n() 
        return {
            i18n
        }
    }
};
</script>

<style lang="scss" scoped>

.mear {
    width: 100%;
    // height: 214rem;
    background: url(../../assets/img/bgh5.png) no-repeat;
    background-size: 100% 100%;

    .mearNav {
        font-size: 10rem;
        font-family: Arial-BoldMT, Arial;
        font-weight: normal;
        color: #CCCCCC;
        text-align: center;
        padding-top: 17rem;
        margin-bottom: 8rem;
        font-weight: 700;
    }

    .borderItem {
        width: 100%;
        display: flex;
        justify-content: center;

        span {
            height: 0.8rem;
            background-color: #1E65D6;

        }

        span:nth-child(1) {
            width: 46px;
        }

        span:nth-child(2) {
            margin-left: 1rem;
            width: 0.8rem;
        }

    }



    h2 {
        font-size: 6.3rem;
        text-align: center;
        margin: 7.8rem 0 10.5rem 0;
    }

    h6 {

        font-size: 5rem;
        font-weight: 500;
        color: #333333;
        text-align: center;
    }

    p {
        width: 100rem;
        font-size: 4.6rem;
        color: #797979;
      
        margin: 6.3rem auto 8.6rem auto;
    }

    .uit {
        width: 85%;
        height: 82rem;
        background-color: #333333;
        margin: 0 auto;
        position: relative;

        img {
            width: 100%;
            height: 100%;

        }

        p {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 10rem;
            background: #000000;
            opacity: 0.45;
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            img {
                width: 7rem;
                height: 7rem;
                margin: 0 2rem;
            }
        }
    }
}
</style>