<template>
    <div class="newItem" :style="{height:i18n.locale.value==='zh'? '207rem' : '260rem'}">
        <div class="newNav">News Information</div>
        <div class="borderItem">
            <span></span> <span></span>
        </div>
        <h2>{{ $t('meg.news') }}</h2>
        <h6>{{ $t('meg.regard') }}</h6>
        <p>{{ $t('meg.newTitle') }}</p>
        <span class="span" @click="btn">{{ $t('meg.newDetail') }}</span>
   </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
export default {
   setup(){
    const i18n = useI18n()
    const btn = () =>{
    window.location.href = "https://www.vsteach.com/#/about"

    }
    return {
        btn,
        i18n
    }
}
};
</script>

<style lang="scss" scoped>
.newItem{
    width: 100%;
    height: 207rem;
    background: url(../../assets/img/bg2h5.png) no-repeat;
    background-size: 100% 100%;
    .newNav {
        font-size: 10rem;
        font-family: Arial-BoldMT, Arial;
   
        color: #E8E8E8;
        text-align: center;
        padding-top: 25rem;
        margin-bottom: 8.8rem;
        font-weight: 700;
    }
    .borderItem {
        width: 100%;
        display: flex;
        justify-content: center;

        span {
            height: 0.8rem;
            background-color: #fff;
         
        }

        span:nth-child(1) {
            width: 46px;
        }

        span:nth-child(2) {
            margin-left: 1rem;
            width: 0.8rem;
        }

    }

    

    h2 {
        font-size: 6.3rem;
        text-align: center;
        margin: 7.8rem 0 36rem 0;
        color: #fff;
        font-family: Arial-BoldMT, Arial;
    }

    h6 {

        font-size: 5rem;
        font-weight: 500;
        color: #fff;
        text-align: center;
    }

    p {
        width: 90rem;
        font-size: 4.6rem;
        color: #fff;
   
        line-height: 8rem;
        margin: 9rem auto 12.5rem auto;
    }
   .span{
        display: block;
        margin: 0 auto;
        width: 50rem;
        height: 14.5rem;
        line-height: 14.5rem;
        text-align: center;
        background-color: #fff;
        border-radius: 1rem;
        font-size: 5rem;
        color: #1E65D6;
    }

}
</style>