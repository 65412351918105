import AMapLoader from '@amap/amap-jsapi-loader'

const mapLoader = (row = []) => {
  return new Promise((resolve, reject) => {
    AMapLoader.load({
      key: 'f8fe4a1cd0445f06f4191344755cf31b', // 申请好的Web端开发者Key，首次调用 load 时必填
      version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      plugins: row, // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      AMapUI: { // 是否加载 AMapUI，缺省不加载
        version: '1.1', // AMapUI 缺省 1.1
        plugins: [] // 需要加载的 AMapUI ui插件
      },
      Loca: { // 是否加载 Loca， 缺省不加载
        version: '2.0.0' // Loca 版本，缺省 1.3.2
      }
    }).then((AMap) => {
      resolve(AMap)
    }).catch(err => {
      // console.log(err)
      reject(err)
    })
  })
}

export { mapLoader }
