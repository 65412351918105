<template>
    <div  :class="i18n.locale.value==='zh'? 'merchant':'merchants' ">


        <div class="products">
            <p>Merchant<br />
                Products</p>
            <div class="border">
                <span></span>
                <span></span>
            </div>
            <h2>{{ $t('meg.merchants') }}</h2>
            <div class="text">
                <h5>{{ $t('meg.machine') }}</h5>
                <p>{{ $t('meg.porduceDest') }}</p>
            </div>
        </div>
        <div class="video">
            <img src="../assets/img/3D.jpg" alt="" class="img">
            <p><span @click="btn">{{ $t('meg.interaction') }}</span></p>
        </div>
  

        <el-dialog v-model="dialogVisible" width="50%" destroy-on-close="true" center="true"
            @keydown.esc="dialogVisible = false">
            <iframe id="unity-infame" ref="frame" src="/static/unity.html" frameborder="0" scrolling="no"></iframe>
        </el-dialog>
    </div>

</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
export default {
    setup() {
        const i18n = useI18n()
        const dialogVisible = ref(false)
        const btn = () => {
            //dialogVisible.value = true
        }
        return {
            dialogVisible,
            btn,
            i18n
        }
    }
}
</script>


<style scoped lang="scss">
#unity-infame {
    height: 610px;
    width: 100%;
}

.mack {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #1E65D6;
}

:deep(.el-dialog__header) {
    padding: 0;
    margin: 0;
}

:deep(.el-dialog__body) {
    padding: 8px;
    margin: 0;
}

:deep(.el-dialog__close) {
    width: 30px;
    height: 30px;
    background: #000000;
    opacity: 0.5;
    border-radius: 50%;
    color: #FFFFFF;
}

.merchant {
    width: 100%;
    height: 61.875rem;
    background: url(../assets/img/bg.png) no-repeat;
    background-size: 100% 100%;
    display: flex;
    position: relative;

    .products {
        margin: 15.125rem 0 0 23rem;
        flex-shrink: 0;

        p {
            font-size: 3.375rem;
            font-family: Arial;
            color: #D4D4D4;

        }

        .border {
            margin-top: 1rem;

            span {
                display: inline-block;
                background-color: #1E65D6;
            }

            span:nth-child(1) {
                width: 4.6875rem;
                height: .25rem;
            }

            span:nth-child(2) {
                margin-left: .3125rem;
                width: .25rem;
                height: .25rem;
            }
        }

        h2 {
            margin-top: 3rem;
            font-size: 1.875rem;
            font-family: Light;
            font-weight: bold;
            color: #333333;
        }

        .text {
            margin-top: 8.5rem;

            h5 {
                font-size: 1.5rem;
                font-family: Light;
                color: #333333;
                margin: -3.25rem 0 2.6563rem 0;
                font-weight: normal;
                width: 25rem;
            }

            p {
                font-family: Light;
                color: #797979;
                font-size: 1rem;
                line-height: 36px;
                // width: 470px;
                width: 25rem;
            }
        }
    }

    .video {
        flex-shrink: 0;
        width: 56.25rem;
        height: 42.1875rem;
        margin: 9.625rem 0 0 3.125rem;

        position: relative;

        .img {
            width: 100%;
            height: 100%;
        }

        p {
            width: 100%;
            position: absolute;
            bottom: 0;
            height: 55px;
            background: #000000;
            opacity: 0.2;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            span {
                color: #FFFFFF;
                font-size: 18px;
                margin-right: 20px;
                cursor: pointer;
            }


        }
    }
}
.merchants {
    width: 100%;
    height: 61.875rem;
    background: url(../assets/img/bg.png) no-repeat;
    background-size: 100% 100%;
    display: flex;
    position: relative;

    .products {
        margin: 10.125rem 0 0 23rem;
        flex-shrink: 0;

        p {
            font-size: 3.375rem;
            font-family: Arial;
            color: #D4D4D4;

        }

        .border {
            margin-top: 1rem;

            span {
                display: inline-block;
                background-color: #1E65D6;
            }

            span:nth-child(1) {
                width: 4.6875rem;
                height: .25rem;
            }

            span:nth-child(2) {
                margin-left: .3125rem;
                width: .25rem;
                height: .25rem;
            }
        }

        h2 {
            margin-top: 3rem;
            font-size: 1.875rem;
            font-family: Light;
            font-weight: bold;
            color: #333333;
        }

        .text {
            margin-top: 8.5rem;

            h5 {
                font-size: 1.5rem;
                font-family: Light;
                color: #333333;
                margin: -3.25rem 0 2.6563rem 0;
                font-weight: normal;
                width: 25rem;
            }

            p {
                font-family: Light;
                color: #797979;
                font-size: 1rem;
                line-height: 36px;
                // width: 470px;
                width: 25rem;
            }
        }
    }

    .video {
        flex-shrink: 0;
        width: 56.25rem;
        height: 42.1875rem;
        margin: 9.625rem 0 0 3.125rem;

        position: relative;

        .img {
            width: 100%;
            height: 100%;
        }

        p {
            width: 100%;
            position: absolute;
            bottom: 0;
            height: 55px;
            background: #000000;
            opacity: 0.2;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            span {
                color: #FFFFFF;
                font-size: 18px;
                margin-right: 20px;
                cursor: pointer;
            }


        }
    }
}
@media screen and (max-width: 1550px) {
    .merchant {
        width: 100%;
        height: 726px;

        .products {
            margin: 10.125rem 0 0 12rem;

            p {
                font-size: 54px;
            }

            .border {
                margin-top: 1rem;

                span:nth-child(1) {
                    width: 70px;
                    height: 4px;
                }

                span:nth-child(2) {
                    margin-left: 5px;
                    width: 4px;
                    height: 4px;
                }
            }

            h2 {
                margin-top: 3rem;
                font-size: 30px;
            }

            .text {
                margin-top: 8.5rem;

                h5 {
                    font-size: 24px;
                    margin: -3.75rem 0 .6563rem 0;
                    width: 540px;
                }

                p {
                    font-size: 16px;
                    width: 530px;
                }
            }
        }

        .video {

            width: 650px;
            height: 500px;
            margin: 7.625rem 0 0 3.125rem;

            #viewer {
                width: 100%;
                height: 100%;
            }


        }
    }


}
@media screen and (max-width: 1550px) {
    .merchants {
        width: 100%;
        height: 726px;

        .products {
            margin: 6.125rem 0 0 12rem;

            p {
                font-size: 54px;
            }

            .border {
                margin-top: 1rem;

                span:nth-child(1) {
                    width: 70px;
                    height: 4px;
                }

                span:nth-child(2) {
                    margin-left: 5px;
                    width: 4px;
                    height: 4px;
                }
            }

            h2 {
                margin-top: 3rem;
                font-size: 30px;
            }

            .text {
                margin-top: 8.5rem;

                h5 {
                    font-size: 24px;
                    margin: -3.75rem 0 .6563rem 0;
                    width: 540px;
                }

                p {
                    font-size: 16px;
                    width: 530px;
                    line-height: 28px;
                }
            }
        }

        .video {

            width: 650px;
            height: 500px;
            margin: 7.625rem 0 0 3.125rem;

            #viewer {
                width: 100%;
                height: 100%;
            }


        }
    }


}
@media screen and (max-width: 1366px) {
    .merchant {
        width: 100%;
        height: 726px;

        .products {
            margin: 6.125rem 1rem 0 17rem;

            p {
                font-size: 54px;
            }

            .border {
                margin-top: 1rem;

                span:nth-child(1) {
                    width: 70px;
                    height: 4px;
                }

                span:nth-child(2) {
                    margin-left: 5px;
                    width: 4px;
                    height: 4px;
                }
            }

            h2 {
                margin-top: 3rem;
                font-size: 30px;
            }

            .text {
                margin-top: 8.5rem;

                h5 {
                    font-size: 24px;
                    margin: 4.25rem 0 .6563rem 0;
                    width: 400px;
                }

                p {
                    font-size: 16px;
                    width: 400px;
                   
                }
            }
        }

        .video {

            width: 650px;
            height: 500px;
            margin: 8.625rem 0 0 3.125rem;

            #viewer {
                width: 100%;
                height: 100%;
            }


        }
    }
}
</style>