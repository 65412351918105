/**
 * @fileoverview gRPC-Web generated client stub for pbs
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.11.4
// source: company.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var common_pb = require('./common_pb.js')
const proto = {};
proto.pbs = require('./company_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.pbs.CompanyServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.pbs.CompanyServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Company,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_CompanyService_Create = new grpc.web.MethodDescriptor(
  '/pbs.CompanyService/Create',
  grpc.web.MethodType.UNARY,
  proto.pbs.Company,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Company} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.Company} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.CompanyServiceClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.CompanyService/Create',
      request,
      metadata || {},
      methodDescriptor_CompanyService_Create,
      callback);
};


/**
 * @param {!proto.pbs.Company} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     Promise that resolves to the response
 */
proto.pbs.CompanyServicePromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.CompanyService/Create',
      request,
      metadata || {},
      methodDescriptor_CompanyService_Create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.UpdateCompanyReq,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_CompanyService_Update = new grpc.web.MethodDescriptor(
  '/pbs.CompanyService/Update',
  grpc.web.MethodType.UNARY,
  proto.pbs.UpdateCompanyReq,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.UpdateCompanyReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.UpdateCompanyReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.CompanyServiceClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.CompanyService/Update',
      request,
      metadata || {},
      methodDescriptor_CompanyService_Update,
      callback);
};


/**
 * @param {!proto.pbs.UpdateCompanyReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     Promise that resolves to the response
 */
proto.pbs.CompanyServicePromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.CompanyService/Update',
      request,
      metadata || {},
      methodDescriptor_CompanyService_Update);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.CompanyId,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_CompanyService_Delete = new grpc.web.MethodDescriptor(
  '/pbs.CompanyService/Delete',
  grpc.web.MethodType.UNARY,
  proto.pbs.CompanyId,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.CompanyId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.CompanyId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.CompanyServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.CompanyService/Delete',
      request,
      metadata || {},
      methodDescriptor_CompanyService_Delete,
      callback);
};


/**
 * @param {!proto.pbs.CompanyId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     Promise that resolves to the response
 */
proto.pbs.CompanyServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.CompanyService/Delete',
      request,
      metadata || {},
      methodDescriptor_CompanyService_Delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.GetCompanyListReq,
 *   !proto.pbs.Companies>}
 */
const methodDescriptor_CompanyService_GetCompanyList = new grpc.web.MethodDescriptor(
  '/pbs.CompanyService/GetCompanyList',
  grpc.web.MethodType.UNARY,
  proto.pbs.GetCompanyListReq,
  proto.pbs.Companies,
  /**
   * @param {!proto.pbs.GetCompanyListReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Companies.deserializeBinary
);


/**
 * @param {!proto.pbs.GetCompanyListReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pbs.Companies)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Companies>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.CompanyServiceClient.prototype.getCompanyList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.CompanyService/GetCompanyList',
      request,
      metadata || {},
      methodDescriptor_CompanyService_GetCompanyList,
      callback);
};


/**
 * @param {!proto.pbs.GetCompanyListReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Companies>}
 *     Promise that resolves to the response
 */
proto.pbs.CompanyServicePromiseClient.prototype.getCompanyList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.CompanyService/GetCompanyList',
      request,
      metadata || {},
      methodDescriptor_CompanyService_GetCompanyList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.RegisterCompanyReq,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_CompanyService_Register = new grpc.web.MethodDescriptor(
  '/pbs.CompanyService/Register',
  grpc.web.MethodType.UNARY,
  proto.pbs.RegisterCompanyReq,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.RegisterCompanyReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.RegisterCompanyReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.CompanyServiceClient.prototype.register =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.CompanyService/Register',
      request,
      metadata || {},
      methodDescriptor_CompanyService_Register,
      callback);
};


/**
 * @param {!proto.pbs.RegisterCompanyReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     Promise that resolves to the response
 */
proto.pbs.CompanyServicePromiseClient.prototype.register =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.CompanyService/Register',
      request,
      metadata || {},
      methodDescriptor_CompanyService_Register);
};


module.exports = proto.pbs;

