<template>
  <div class="home">
    <div class="header" v-if="(screenWidth >= 1024)">
      <div class="logo">
        <img src="../assets/img/logo@2x.png" alt="">
        <h2>{{ $t('meg.logo') }}</h2>
      </div>
      <div class="nav" v-if="i18n.locale.value === 'zh'">
        <p v-for="(item, i) in list" :key="i" @click="active(i)" :class="index === i ? 'active' : ''">{{ item.title }}
        </p>
      </div>
      <div class="nav" v-else>
        <p v-for="(item, i) in lists" :key="i" @click="active(i)" :class="index === i ? 'active' : ''">{{ item.title }}
        </p>
      </div>
      <div class="icon" @click="changelang">
        <img src="../assets/img/zh.png" alt="" v-if="i18n.locale.value === 'zh'">
        <img src="../assets/img/en.png" alt="" v-else>
      </div>
    </div>
    <div class="head" v-else>
      <img src="../assets/img/logo@2x.png" alt=""><span>{{ $t('meg.logos') }}</span>
      <img src="../assets/img/icon_qiehuan.png" alt="" class="img" v-if="i18n.locale.value === 'zh'" @click="changelang">
      <img src="../assets/img/en.png" alt="" class="img" v-else @click="changelang">
    </div>
    <div class="main" v-if="(screenWidth >= 1024)">
      <div class="banner" id="tabs0">
        <Banner></Banner>
      </div>
      <div id="tabs1">
        <Merchant></Merchant>
      </div>
      <div class="news" id="tabs2">
        <News></News>
      </div>
      <div id="tabs3">
        <Ration></Ration>
      </div>
      <div id="tabs4">
        <About></About>
      </div>

    </div>
    <div class="content" v-else>
      <Swiper></Swiper>
      <Mear></Mear>
      <New/>
      <Rat/>
      <Abouts/>
    </div>
    <div class="footer" v-if="(screenWidth >= 1024)">
      <div class="listItem">
        <div class="nav">
          <span>{{ $t('meg.websiteHome') }}</span>
          <span>{{ $t('meg.merchants') }}</span>
          <span>{{ $t('meg.news') }}</span>
          <span>{{ $t('meg.software') }}</span>
          <span>{{ $t('meg.contact') }}</span>
        </div>
        <div class="city">
          <p>{{ $t('meg.company') }}<span>|</span>{{ $t('meg.phone') }}<span>|</span>Email：info@vsteach.com</p>
          <p>Copyright &copy; 2022 www.vsteach.com {{ $t('meg.logo') }} {{ $t('meg.copyright') }} All Rights
            Reserved.<span>|</span>{{ $t('meg.internetContentProvider') }}</p>
        </div>
      </div>
      <div class="qrcode">
        <img src="../assets/img/erweima@2x.png" alt="">
        <p>{{ $t('meg.more') }}</p>
      </div>
    </div>

    <div class="foot"></div>


  </div>
</template>

<script>
import '../utils/rems'
import { ref, onMounted, onBeforeUnmount } from 'vue'
import Banner from '../components/Banner.vue' //轮播图
import Merchant from '../components/Merchant.vue'  //商家商品
import News from '../components/News.vue' // 新闻资讯
import Ration from '../components/Ration.vue' //下载
import About from '../components/About.vue' //联系我们
import { useI18n } from 'vue-i18n' //国际化语言
import Swiper from '../components/move/swiper.vue'
import Mear from '../components/move/mear.vue'
import New from '../components/move/new.vue'
import Rat from '../components/move/rat.vue'
import Abouts from '../components/move/abouts.vue'
export default {
  components: {
    Banner,
    Merchant,
    News, 
    Ration,
    About,
    Swiper,
    Mear,
    New,
    Rat,
    Abouts
  },
  setup() {
    const i18n = useI18n()
    function changelang() {
      i18n.locale.value == 'zh' ? i18n.locale.value = 'en' : i18n.locale.value = 'zh'
    }
    const list = ref([
      {
        title: '首页',
        name: 'tabs0'
      },
      {
        title: '商家产品',
        name: 'tabs1'
      },
      {
        title: '新闻资讯',
        name: 'tabs2'
      },
      {
        title: '下载软件',
        name: 'tabs3'
      },
      {
        title: '联系我们',
        name: 'tabs4'
      },
    ]) //导航数据
    const lists = ref([
      {
        title: 'home',
        name: 'tabs0'
      },
      {
        title: 'Products of merchants',
        name: 'tabs1'
      },
      {
        title: 'News',
        name: 'tabs2'
      },
      {
        title: 'Download software',
        name: 'tabs3'
      },
      {
        title: 'contact us',
        name: 'tabs4'
      },
    ]) //导航数据
    const index = ref(0) //下标
    const mar = ref(false)
    //切换下标高亮
    const active = (i) => {
      index.value = i
      //锚点
      const tabsId = document.getElementById(`tabs${i}`)
      if (tabsId) {
        window.scrollTo({
          top: tabsId.offsetTop - 76,
          behavior: "smooth",
        });
      }
    }
    const scrollTop = ref(null) //滚动轴
    // 获取页面滚动距离
    const scroll = () => {
      if(screenWidth.value>=1024){
         scrollTop.value = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // console.log(scrollTop.value, '滚动距离')
      const banner = document.getElementById('tabs0').offsetTop - document.documentElement.scrollTop
      const produc = document.getElementById('tabs1').offsetTop - document.documentElement.scrollTop
      const news = document.getElementById('tabs2').offsetTop - document.documentElement.scrollTop
      const download = document.getElementById('tabs3').offsetTop - document.documentElement.scrollTop
      const contact = document.getElementById('tabs4').offsetTop - document.documentElement.scrollTop
      if (banner <= 75) {
        index.value = 0
      }
      if (produc <= 200) {
        index.value = 1
      }
      if (news <= 200) {
        index.value = 2
      }
      if (download <= 200) {
        index.value = 3
      }
      if (contact <= 200) {
        index.value = 4
      }
      }
  
    }
    const screenWidth = ref(null)
    onMounted(() => {
      window.addEventListener('scroll', scroll) // 监听页面滚动

      screenWidth.value = document.body.clientWidth
      window.onresize = () => {
        // console.log('高度','>>>>>>>>????????');
        // console.log(screenWidth.value);
        //屏幕尺寸变化
        return (() => {
          screenWidth.value = document.body.clientWidth
          // console.log(screenWidth.value, '<<<<<<<<<<')
        })()
      }

    })

    onBeforeUnmount(() => {
      scrollTop.value = null
      window.removeEventListener('scroll', scroll);
    })
    return {
      list,
      lists,
      active,
      index,
      mar,
      scroll,
      i18n,
      changelang,
      screenWidth
    }
  }
};
</script>

<style scoped lang="scss">
.home {
  width: 100%;
  height: 100%;
}

.header {
  width: 100%;
  height: 76px;
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #fff;
  display: flex;
  justify-content: space-around;

  .logo {
    margin: 0 0 0 14rem;
    width: 456px;
    display: flex;
    align-items: center;
    flex-shrink: 0;


    img {
      width: 52px;
      height: 52px;
    }

    h2 {
      color: #647FAB;
      font-size: 16px;
      margin-left: 0.3rem;
      flex-shrink: 0;
      font-family: Light;
    }
  }

  .nav {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    width: 630px;


    p {
      font-family: Light;
      color: #333333;
      margin-right: 47px;
      font-size: 16px;
      line-height: 36px;
      flex-shrink: 0;
    }

    p:hover {
      color: #003893;
      cursor: pointer;
    }

    .active {
      color: #003893;
      border-bottom: 3px solid #003893;
    }
  }

  .icon {
    width: 36px;
    height: 36px;
    margin-top: 20px;
    cursor: pointer;

    // position: fixed;
    // top: 20px;
    // right: 50px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

@media all and (max-width: 1550px) {
  .header {
    display: flex;
    justify-content: space-around;

    .logo {
      margin: 0 0 0 50px;
    }
  }
}

.footer {
  width: 100%;
  height: 24.125rem;
  background-color: #F5F5F5;
  display: flex;
  align-items: center;
  justify-content: space-around;


  .listItem {
    .nav {
      border-bottom: 1px solid #ccc;
      display: flex;
      justify-content: space-between;

      span {

        margin-bottom: 4.4rem;
        font-size: 16px;
        font-family: Light;
        color: #333333;
        flex-shrink: 0;
      }
    }
  }

  .city {

    p {
      font-size: 12px;
      font-family: Light;

      color: #999;
      margin: 0.826rem 0 0.562rem 0;
    }

    p:nth-child(1) {
      margin-top: 1.06rem;
    }

    p:nth-child(2) {
      margin-top: 0.6rem;
    }

    span {
      padding: 30px;
    }
  }


  .qrcode {

    text-align: center;


    img {
      width: 110px;
      height: 110px;
    }

    p {
      margin-top: 1rem;
      font-size: 12px;
      color: #999999;
      font-family: Light;
    }
  }
}



@media all and (max-width: 1366px) {
  .header {
    width: 1366px;
  }
}


.head {
  width: 100%;
  height: 18rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #fff;
  img {
    width: 10rem;
    height: 10rem;
  }

  span {
    font-size: 6rem;
    font-family: AlibabaPuHuiTi-Bold, AlibabaPuHuiTi;
    font-weight: bold;
    color: #647FAB;
    margin-left: 5px;
  }
  .img{
    width: 10rem;
    height: 10rem;
    position: fixed;
    right: 10px;
  }
}
.content{
  margin-top: 18rem;
  flex: 1;
  overflow-y: auto;
}
</style>
